import { text } from "stream/consumers";
import { fetchCountryInfo } from "./Services";

export const addJob = async ({ title, description, expireDate }: {
    title: string;
    description: any;
    expireDate: string;
}) => {
    try {
        const baseURL = await fetchCountryInfo();
        const response = await fetch(`${baseURL}/wp-json/custom-forminator/v1/submit-form/8143`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "text-1": title,
                "textarea-1": description,
                "date-1": expireDate,
            }),
        });
        console.log('response', response);
        const data = await response.json();
        console.log('data', data);
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getJobs = async () => {
    try {
        const baseURL = await fetchCountryInfo();
        const response = await fetch(`${baseURL}/wp-json/custom-forminator/v1/view-form/8143`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
}

export const applyForJob = async ({ jobTitle, name, email, phone, resume }: {
    name: string;
    jobTitle: string;
    email: string;
    phone: string;
    resume: string;
}) => {
    try {
        const baseURL = await fetchCountryInfo();

        const body = JSON.stringify({
            "textarea-1": jobTitle,
            "name-1": name,
            "email-1": email,
            "phone-1": phone,
            "date-1": new Date().toLocaleDateString(),
            "upload-1": resume,
        });

        const [response] = await Promise.all([
            fetch(`${baseURL}/wp-json/custom-forminator/v1/submit-form/8144`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body,
            })
        ]);

        const data = await response.json();
        return data;
    } catch (error) {
        console.log('api error: ', error);
    }
};

export const getJobApplications = async () => {
    try {
        const baseURL = await fetchCountryInfo();
        const response = await fetch(`${baseURL}/wp-json/custom-forminator/v1/view-form/8144`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error);
    }
}