import React, { useEffect, useReducer, useState } from 'react'
import { Button, Container, Modal } from 'react-bootstrap'
import { careersContent } from './careersContent'
import MetaTag from '../component/Common/MetaTag.tsx'
import { applyForJob, getJobs } from '../services/jobs.ts'
import Loading from './loading.js'

const initialState = {
    jobs: [],
    loading: false,
    formModalVisible: false,
    jobTitle: '',
    name: '',
    email: '',
    phone: '',
    message: '',
    file: '',
    applyingLoading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'jobs':
            return { ...state, jobs: action.payload };
        case 'loading':
            return { ...state, loading: action.payload };
        case 'formModalVisible':
            return { ...state, formModalVisible: action.payload };
        case 'jobTitle':
            return { ...state, jobTitle: action.payload };
        case 'name':
            return { ...state, name: action.payload };
        case 'email':
            return { ...state, email: action.payload };
        case 'phone':
            return { ...state, phone: action.payload };
        case 'message':
            return { ...state, message: action.payload };
        case 'file':
            return { ...state, file: action.payload };
        case 'applyingLoading':
            return { ...state, applyingLoading: action.payload };
        case 'reset':
            return initialState;
        default:
            return state;
    }
}

export default function Careers() {
    const [states, setStates] = useReducer(reducer, initialState);
    const { jobs, loading } = states || {};

    const dispatchState = (type, payload) => {
        setStates({ type, payload });
    }

    useEffect(() => {
        dispatchState('loading', true);
        getJobs().then((res) => {
            if (res.status === 200) {
                dispatchState('jobs', res.entries);
            }
        }).catch((err) => {
            // console.log(err);
        }).finally(() => {
            setStates({ type: 'loading', payload: false });
        });
    }, []);

    const handleApply = (data) => {
        dispatchState('formModalVisible', true);
        dispatchState('jobTitle', data);
    }

    // create a function to convert selected file from html input to base64-encoded string
    const fileToBase64 = (file) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                dispatchState('file', reader.result);
            };
            reader.onerror = (error) => {
                // console.log(error);
            };
        } catch (error) {
            // console.log(error);
        }
    }

    const handleSubmit = async () => {
        // check if all fields are filled
        const { name, email, phone, message, file } = states;
        const condition = name === '' || email === '' || phone === '' || file === '';
        if (condition) {
            alert('Please fill all fields');
            return;
        }
        dispatchState('applyingLoading', true);
        await applyForJob({
            jobTitle: states.jobTitle,
            name,
            email,
            phone,
            message,
            resume: file,
        }).then((res) => {
            if (res && res.status === 200) {
                alert(res?.message);
                dispatchState('reset');
            } else if (res && res.status === 400) {
                alert('Failed to apply for job');
            }
        }
        ).catch((err) => {
            alert('Failed to apply for job' + err);
        }).finally(() => {
            dispatchState('applyingLoading', false);
            window.location.reload();
        });
    }

    return (
        <>
            <MetaTag
            // title='Jobs & Careers at Q Academy | Empower Learners, Shape the Future' 
            // description='Join us at Q Academy in our mission to provide top-tier courses for all, and embark on your journey toward a fulfilling career today. Explore Job Openings!'
            // keywords='Job Openings at Q Academy'
            />
            <Container className='mt-5'>
                <h2 className='mb-5 mt-5' >Available Jobs</h2>
                {
                    loading ? <Loading /> : jobs.map((job, i) => (
                        <div className='mb-5'>
                            <h3>{job["text-1"]?.value}</h3>
                            <p dangerouslySetInnerHTML={{ __html: job["textarea-1"]?.value }} />
                            <p> expires on {job["date-1"]?.value}</p>
                            <Button className='mt-3 w-25' onClick={() => handleApply(job["text-1"]?.value)}>Apply</Button>
                        </div>
                    ))
                }

                <Modal show={states.formModalVisible} onHide={() => dispatchState('formModalVisible', false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Apply for {states.jobTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control" id="name" onChange={(e) => dispatchState('name', e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email" onChange={(e) => dispatchState('email', e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone</label>
                                <input type="text" className="form-control" id="phone" onChange={(e) => dispatchState('phone', e.target.value)} />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea className="form-control" id="message" rows="3" onChange={(e) => dispatchState('message', e.target.value)}></textarea>
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="file" className="form-label">Resume</label>
                                <input type="file" className="form-control" id="file" onChange={(e) => fileToBase64(e.target.files[0])} />
                            </div>
                            <Button variant="primary" 
                            // type="submit" 
                            onClick={handleSubmit}
                            disabled={states.applyingLoading}>
                                {states.applyingLoading ? 'Loading.....' : 'Submit'}
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>


                {/* {
                    careersContent.map((career, i) => (
                        <div className='mb-5'>
                            <h3>{career.jobTitle}</h3>
                            {
                                career.description.map((description, i) => (
                                    <>
                                        <h4 className='mt-3'>{description.title}</h4>
                                        {
                                            typeof description.description === 'string' ? <p>{description.description}</p> : typeof description.description === 'object' && <ul className='mt-3 mb-3'>
                                                {
                                                    description.description.map((description, i) => (
                                                        <li key={i} >{description}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        {
                                            description?.visit && <p dangerouslySetInnerHTML={{ __html: description.visit }}></p>
                                        }
                                    </>
                                ))
                            }
                            <h4>Responsibilities :</h4>
                            <ul className='mt-3 mb-3'>
                                {
                                    career.responsibilities.map((responsibility, i) => (
                                        <li key={i} className='mt-3' >
                                            <h5 className={['', undefined, null, 0].includes(responsibility?.description?.length) && 'text-secondary'} >{responsibility.title}</h5>
                                            <ul>
                                                {
                                                    responsibility?.description?.length > 0 && responsibility?.description.map((description, i) => (
                                                        <li key={i} >{description}</li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                    ))
                                }
                            </ul>
                            <h4>{career.qualifications?.length > 0 && 'Qualifications :'}</h4>
                            <ul>
                                {
                                    career.qualifications?.map((qualification, i) => (
                                        <li key={i} >
                                            <p>{qualification}</p>
                                        </li>
                                    ))
                                }
                            </ul>
                            <p>{career.application}</p>
                            {i !== careersContent.length - 1 &&
                                <hr style={{
                                    border: '1px solid #000000',
                                }} className='mt-5' />
                            }
                        </div>
                    ))
                } */}
            </Container>
        </>
    )
}
